import Axios from 'axios';
import React from 'react';
import fileDownload from 'js-file-download';
import { audience, baseUrl } from 'api/helpers'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

const DocumentLoading = _ => (
  <div>
    <Spinner animation="border" size='sm' variant="info" />
    <span className='ml-1'>Generating document...</span>
  </div>
)

export async function fetchPdf({token, student, documentType}) {
  const toastId = toast(<DocumentLoading />)
  const url = `${baseUrl}/students/${student.id}/pdfs?document_type=${documentType}`
  Axios.get(url, {
    responseType: 'blob',
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(res => {
    fileDownload(res.data, `${student.full_name} Exemption Letter.pdf`);
  }).then(() => {
    toast.dismiss(toastId)
    toast.success("Download complete! Copy saved under Documents tab")
  });
}
