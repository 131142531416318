import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { fetchPdf } from 'api/pdf'
import {
  fetchStudentAcademicPlans,
  createStudentAcademicPlan,
  destroyStudentAcademicPlan,
} from 'api/academic_progress_plans'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import DefaultSpinner from 'helpers/default-spinner'
import { formatDate } from 'helpers/datetime'
import Button from 'react-bootstrap/Button'
import { currentSchoolYear } from 'helpers/school-dates'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faDownload } from '@fortawesome/free-solid-svg-icons'
import ConfirmDelete from 'helpers/confirm-delete'
import Card from 'react-bootstrap/Card'

function AcademicProgressPlans({ student }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const {
    isSuccess: planIsSuccess,
    isLoading: planIsLoading,
    data: academicPlans,
  } = useQuery(`students/${student.id}/academic_progress_plans`, async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchStudentAcademicPlans({
      studentId: student.id,
      token,
    })
    return data
  })

  const createNewPlan = async () => {
    const token = await getAccessTokenSilently({ audience })
    const params = { year: currentSchoolYear(), student_id: student.id }

    newAcademicPlanMutation.mutate({ params, token })
    toast.success('Academic Plan created!')
  }

  const newAcademicPlanMutation = useMutation(
    ({ params, token }) => createStudentAcademicPlan({ params, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `students/${student.id}/academic_progress_plans`
        )
        queryClient.invalidateQueries('students')
      },
    }
  )

  const deletePlanMutation = useMutation(
    ({ id, token }) => destroyStudentAcademicPlan({ id, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          `students/${student.id}/academic_progress_plans`
        )
      },
    }
  )

  const handleDelete = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deletePlanMutation.mutate({
      id: id,
      token: token,
    })
  }

  const downloadAcademicPlanPdf = async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchPdf({
      student,
      token,
      documentType: 'academic_progress_plan',
    })
    return data
  }

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <h5 className="mb-3 text-info">Academic Progress Plans (IPRI)</h5>
        {planIsSuccess &&
          academicPlans.filter((a) => a.year === currentSchoolYear()).length ===
            0 && (
            <Alert variant="primary">
              <Button variant="light" onClick={() => createNewPlan()}>
                <FontAwesomeIcon icon={faPlus} className="text-primary" />{' '}
                Create APP/IPRI
              </Button>
              <span className="align-middle ml-2">
                This student does not have an academic plan for this year.{' '}
              </span>
            </Alert>
          )}
        <Table className="mb-5 bg-white">
          <thead>
            <tr>
              <th className="text-info">School Year</th>
              <th className="text-info">Created On</th>
              <th className="text-info">Supports</th>
              <th className="text-info">Interventions</th>
              <th className="text-info">Conferences</th>
              <th className="text-info">Actions</th>
            </tr>
          </thead>
          <tbody>
            {planIsLoading && (
              <tr>
                <td colSpan={5}>
                  <DefaultSpinner />
                </td>
              </tr>
            )}

            {planIsSuccess &&
              academicPlans.length > 0 &&
              academicPlans.map((ap) => (
                <tr key={`academic-plan-${ap.id}`}>
                  <td>
                    <a href={`/academic-plan/${ap.id}`}>{ap.year}</a>
                  </td>
                  <td>{formatDate(ap.created_at)}</td>
                  <td className="text-center">
                    {ap.academic_plan_supports_count}
                  </td>
                  <td className="text-center">
                    {ap.academic_plan_interventions_count}
                  </td>
                  <td className="text-center">
                    {ap.academic_plan_conferences_count}
                  </td>
                  <td style={{ minWidth: '100px' }}>
                    <Button
                      variant="light"
                      className="text-info mr-1"
                      size="sm"
                      onClick={() => downloadAcademicPlanPdf()}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <ConfirmDelete
                      id={ap.id}
                      handleDelete={handleDelete}
                      extraConfirm={true}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default AcademicProgressPlans
